import React from 'react'
import Main from '../Main/Main'
import Contact from '../ContactUs/Contact'
import PolicyMain from '../../assets/Privacy/PolicyMain'
import {  Route, Routes } from 'react-router-dom';
import EditorialPage from '../../assets/Privacy/EditorialPage'
import AuthorshipPage from '../../assets/Privacy/AuthorshipPage'
import SignUpForm from '../Header/SignupForm'
import Home from '../Main/Home'
import Gallery from '../Gallery/Gallery';
import CardDetail from '../Gallery/CardDetail';

const AppRoutes = () => {
  return (
    <>
    <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/policy" element={<PolicyMain />} />
        <Route path="/signup" element={<SignUpForm />} />
        <Route path="/policy" element={<PolicyMain />}>
          <Route index element={<EditorialPage />} />
          <Route path="editorial-policies" element={<EditorialPage />} />
          <Route path="authorship-policies" element={<AuthorshipPage />} />
          
        </Route>
        <Route path='/gallery' element = {<Gallery/>}/>
        <Route path="/gallery/topics/:id" element={<CardDetail />} />
        </Routes>
    </>
  )
}

export default AppRoutes
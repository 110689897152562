import React from 'react'
import SideNavbar from './SideNavbar'
import '../../Styles/policy.css'
import { Outlet } from 'react-router-dom';


const PolicyMain = () => {
  return (
    <div className='policy-container'>
      <SideNavbar/>
      <Outlet/>
    </div>
  )
}

export default PolicyMain
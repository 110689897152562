import React from 'react';
import { Link } from 'react-router-dom'
const EditorialPage = () => {
  return (
    <div className="policy-part-container">
      <h1 className="policy-title">Editorial policies</h1>
      <p className="policy-description">
        The Nature Portfolio journals' editorial policies for primary scientific research can be found on the pages listed below. The Nature Portfolio includes all journals with Nature in their name, the Communications journals, the <em>npj</em> series, <em>Scientific Reports</em>, and <em>Scientific Data</em>. A list of these journals and a description of the relationship between them can be found <a className="policy-a-link" href="#">here</a>. In August 2015, the Nature Portfolio journals became members of the <a className="policy-a-link" href="#">Committee on Publication Ethics (COPE)</a>. We will be guided by COPE guidelines, however, the Nature Portfolio journals will continue to make independent decisions based on our existing policies and principles.
      </p>
      <p className="policy-description">
        For details on the editorial policies of any non-Nature-Portfolio journal on nature.com, please visit their website.
      </p>
      
      <h2 className="policy-section-title"><a className="policy-a-link" href="#">Communicate with respect</a></h2>
      <p className="policy-section-description">
        Only through relationships based upon mutual respect can we build trust and deliver quality publishing products and services to the communities we serve.
      </p>
      
      <h2 className="policy-section-title"><Link to="/policy/authorship-policies" className="policy-a-link" >Authorship</Link></h2>
      <p className="policy-section-description">
        The Nature Portfolio journals' authorship policy (including Authorship: inclusion & ethics in global research).
      </p>

      <h2 className="policy-section-title"><a className="policy-a-link" href="#">Competing interests</a></h2>
      <p className="policy-section-description">
        Nature Portfolio journals' competing interests policies.
      </p>

      <h2 className="policy-section-title"><a className="policy-a-link" href="#">Ethics and Biosecurity</a></h2>
      <p className="policy-section-description">
        Nature Portfolio journals' editorial policies on publishing primary research reporting experiments on living organisms; and on biosecurity and bioethics.
      </p>

      <h2 className="policy-section-title"><a className="policy-a-link" href="#">Artificial Intelligence (AI)</a></h2>
      <p className="policy-section-description">
        Nature Portfolio journals' policies on the use of artificial intelligence.
      </p>

      <h2 className="policy-section-title"><a className="policy-a-link" href="#">Reporting standards and availability of data, materials, code, and protocols</a></h2>
      <p className="policy-section-description">
        Nature Portfolio journals' policy on availability of materials and data, and reporting requirements for life sciences research articles.
      </p>

      <h2 className="policy-section-title"><a className="policy-a-link" href="#">Image integrity and standards</a></h2>
      <p className="policy-section-description">
        Nature Portfolio journals' policies and guidelines on digital images and their manipulation.
      </p>

      <h2 className="policy-section-title"><a className="policy-a-link" href="#">Plagiarism and duplicate publication</a></h2>
      <p className="policy-section-description">
        The Nature Portfolio journals' policies on plagiarism and on providing due credit for published and unpublished data.
      </p>

      <h2 className="policy-section-title"><a className="policy-a-link" href="#">Corrections, Retractions, and Matters Arising</a></h2>
      <p className="policy-section-description">
        Nature Portfolio journals' policy on corrections, retractions, and other amendments to published material.
      </p>

      <h2 className="policy-section-title"><a className="policy-a-link" href="#">Peer Review</a></h2>
      <p className="policy-section-description">
        Nature Portfolio journals' advice for peer-reviewers, and policies relevant to the peer-review process.
      </p>

      <h2 className="policy-section-title"><a className="policy-a-link" href="#">Confidentiality</a></h2>
      <p className="policy-section-description">
        The Nature Portfolio journals' policies on confidentiality.
      </p>

      <h2 className="policy-section-title"><a className="policy-a-link" href="#">Acknowledgements</a></h2>
      <p className="policy-section-description">
        The Nature Portfolio journals’ acknowledgements policies.
      </p>

      <h2 className="policy-section-title"><a className="policy-a-link" href="#">Appeals & Complaints</a></h2>
      <p className="policy-section-description">
        The Nature Portfolio journals’ appeals and complaints policies.
      </p>

      <h2 className="policy-section-title"><a className="policy-a-link" href="#">Preprints & Conference Proceedings</a></h2>
      <p className="policy-section-description">
        Nature Portfolio journals' policy on preprints and conference proceedings.
      </p>

      <h2 className="policy-section-title"><a className="policy-a-link" href="#">Press and embargo policies</a></h2>
      <p className="policy-section-description">
        Nature Portfolio journals' policy on authors' communication with the media and at scientific meetings.
      </p>

      <h2 className="policy-section-title"><a className="policy-a-link" href="#">Self archiving and license to publish</a></h2>
      <p className="policy-section-description">
        Nature Portfolio license to publish policy, and conditions of re-use, for authors.
      </p>

      <h2 className="policy-section-title"><a className="policy-a-link" href="#">Collections and Special Issues</a></h2>
      <p className="policy-section-description">
        Nature Portfolio Journals' policy around Collections and Special Issues.
      </p>
    </div>
  );
}

export default EditorialPage;

import React from 'react'
import Main from './Main'
import HomeElements from './HomeElements'
import '../../Styles/home.css'
import VideoCarousel from './VideoCarousel'
import Services from './Services'

const Home = () => {
  return (
    <div className='home-container'>
    <Main/>
    <HomeElements/>
    <VideoCarousel/>
    <Services/>
    
    </div>
  )
}

export default Home
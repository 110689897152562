import React, { useCallback } from 'react'
import useEmblaCarousel from 'embla-carousel-react'
import '../../Styles/vidocarousel.css'
import Arrow from "../../assets/right.svg";
import Autoplay from 'embla-carousel-autoplay'
import CarouselCard from './CarouselCard';

const VideoCarousel = () => {
  
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true }, [
    Autoplay({ delay: 2000 }),
  ]);

  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev();
  }, [emblaApi]);
  
  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext();
  }, [emblaApi]);
  
  return (
    <div className="carousel-container">
      <h1 className="carousel-head">FEATURED VIDEOS</h1>

      <a id="prev" className="embla__prev action-bt-car" onClick={scrollPrev}>
        <img
          src={Arrow}
          alt=""
          srcset=""
          style={{ transform: "rotate(180deg)" }}
        />
      </a>

      <a id="next" className="embla__next action-bt-car" onClick={scrollNext}>
        <img src={Arrow} alt="" srcset="" />
      </a>

      <div className="embla" style={{margin: 'auto'}} ref={emblaRef}>
        <div className="embla__container embla-container-adder">
        <CarouselCard head="" url=""/>
        <CarouselCard head="" url=""/>
        <CarouselCard/>
        <CarouselCard/>
          
        </div>
        <button class="btn">
  See more 
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" height="15px" width="15px" class="icon">
<path stroke-linejoin="round" stroke-linecap="round" stroke-miterlimit="10" stroke-width="1.5" stroke="#292D32" d="M8.91016 19.9201L15.4302 13.4001C16.2002 12.6301 16.2002 11.3701 15.4302 10.6001L8.91016 4.08008"></path>
</svg>
</button>

      </div>
    </div>
  );
}

export default VideoCarousel
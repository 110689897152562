import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import { BrowserRouter as Router } from 'react-router-dom';
import AppRoutes from './components/Routes/AppRoutes';
import { useEffect } from 'react';
import { changeTitle } from './components/utils/ChangeTitle';


function App() {
  useEffect(()=>{
    changeTitle("Scitome Publications")
    
  },[])
  return (
    <div className="App">
      <Router>
        <Header/>
        <AppRoutes/>
        <Footer/>
      </Router>
    </div>
  );
}

export default App;

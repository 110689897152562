// CardCollection.jsx
import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import '../../Styles/gallery.css';
import GalleryCard from './GalleryCard';
import { changeTitle } from '../utils/ChangeTitle';

const Gallery = ({ 
  title = "Our Collection",
  cards = [
    {
      id: 1,
      imageUrl: "/api/placeholder/400/320",
      heading: "Nature",
      subHeading: "Discover the beauty of natural landscapes",
      description: "A detailed look into the wonders of nature..."
    },
    {
      id: 2,
      imageUrl: "/api/placeholder/400/320",
      heading: "Architecture",
      subHeading: "Explore modern architectural marvels",
      description: "Exploring contemporary architectural designs..."
    },
    // ... more cards
  ]
}) => {
  const navigate = useNavigate();

  const containerVariants = {
    hidden: {},
    visible: {
      transition: {
        staggerChildren: 0.2
      }
    }
  };

  const headingVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: {
        duration: 0.6,
        ease: "easeOut"
      }
    }
  };

  const cardVariants = {
    hidden: { opacity: 0, y: 30 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: {
        duration: 0.5,
        ease: "easeOut"
      }
    }
  };


  
  useEffect(()=>{
    changeTitle("Library | Scitome")
  })

  const handleCardClick = (card) => {
    navigate(`/gallery/topics/${card.id}`, { state: { cardData: card } });
  };

  return (
    <div className="card-collection">
      <motion.h1 
        className="card-collection__title"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, margin: "-100px" }}
        variants={headingVariants}
      >
        {title}
      </motion.h1>

      <motion.div 
        className="card-collection__grid"
        variants={containerVariants}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, margin: "-50px" }}
      >
        {cards.map((card) => (
          <motion.div 
            key={card.id}
            className="card-collection__item"
            variants={cardVariants}
            onClick={() => handleCardClick(card)}
          >
            <GalleryCard {...card} />
          </motion.div>
        ))}
      </motion.div>
    </div>
  );
};

export default Gallery;
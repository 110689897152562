import React, { useState } from "react";
import "../../Styles/contact.css";

const Contact = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    message: "",
  });


  const [isSending, setIsSending] = useState(false) // When the submit button is clicked, the button value should changed to a loading state
  
  const handleChange = (e) => {
    let { name, value } = e.target; // Unpacking element and value
    setFormData({ ...formData, [name]: value }); // updating formdata
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSending(!isSending)
    
    // logic for handling here
    console.log(formData);
  };
  return (
    <div className="scitome-contact-us-container">
      <div className="scitome-form-container">
        <div className="form-header">
          <h1 id="contact-head">Contact us</h1>
          <p className="contact-desc">
            Please add your messages here. We will get back to you soon.
          </p>
        </div>

        <form className="contact-form" onSubmit={handleSubmit}>
          <div className="names">
            <div className="input-component">
              <label className="input-label" htmlFor="firstName">
                First Name:
              </label>
                <input
                className="input-box-contact"
                placeholder="John"
                type="text"
                id="firstName"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                required
              />
            </div>
            <div className="input-component">
              <label className="input-label" htmlFor="lastName">
                Last Name
              </label>

              <input
                className="input-box-contact"
                type="text"
                placeholder="Doe"
                id="lastName"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="input-component">
            <label
              className="input-label"
              htmlFor="email"
              // style={{ marginLeft: "5vw" }}
            >
              Email Address:
            </label>
            <br />
            <input
              className="input-box-contact"
              type="email"
              id="email"
              placeholder="johndoe123@email.com"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="input-component">
            <label
              className="input-label"
              // style={{ marginLeft: "5vw" }}
              htmlFor="message"
            >
              Your Message:
            </label>
            <br />
            <textarea
              id="message"
              name="message"
              placeholder="Enter your message or question"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
          </div>
          <button className="submit-btn-contact" type="submit">
            Submit
          </button>
        </form>
      </div>
      <div className="gradient-banner">
        <img className="banner-image" src="/images/jouranal_cover.jpg" alt="cover image" />
      </div>
    </div>
  );
};

export default Contact;

import React from "react";
import "../../Styles/footer.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faLinkedin,
  faYoutube,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

export const Footer = () => {
  return (
    <footer>
      <div class="footer-links">
        <div className="link-container">
          <h4>Policies</h4>
          <Link to="/policy" className="a-link" href="#">
            policies
          </Link>
          <br />
          <Link to="/" className="a-link" href="#">
            Our Mission
          </Link>
          <br />
          <Link to="/" className="a-link" href="#">
            About Us
          </Link>
          <br />
          <Link to="/contact" className="a-link" href="#">
            Contact Us
          </Link>
        </div>
        <div className="link-container">
          <h4>Instructions</h4>
          <Link to="/" className="a-link" href="#">
            For Authors{" "}
          </Link>
          <br />
          <Link to="/" className="a-link" href="#">
            For Reviewers
          </Link>
          <br />
          <Link to="/" className="a-link" href="#">
            For Editors
          </Link>
        </div>
        <div className="link-container">
          <h4>Careers</h4>
          <Link to="/" className="a-link" href="#">
            Reviewer Roles
          </Link>
          <br />
          <Link to="/" className="a-link" href="#">
            Editor Roles
          </Link>
          <br />
          <Link to="/" className="a-link" href="#">
            Sci-comm Roles
          </Link>
        </div>
      </div>
      <div class="footer">
        <div class="scitome">
          <a href="/" class="logo">
            <svg
              id="graph"
              viewBox="-1 0 12 12"
              xmlns="http://www.w3.org/2000/svg"
            >
              <defs>
                <pattern
                  id="image"
                  x="0%"
                  y="0%"
                  height="100%"
                  width="100%"
                  viewBox="0 0 512 512"
                >
                  <image
                    x="0%"
                    y="0%"
                    width="512"
                    height="512"
                    href="/images/scitome_pitch.svg"
                    preserveAspectRatio="xMidYMid meet"
                  ></image>
                </pattern>
              </defs>

              <circle
                id="sd"
                class="medium"
                cx="5"
                cy="6"
                r="5.5"
                fill="#CEB34580"
              />
              <circle
                cx="5"
                cy="6"
                r="5"
                fill="url(#image)"
                mask="url(#mask)"
              />
            </svg>
          </a>
          <div className="scitome-logo">
            <h1 class="footer-title">SCITOME</h1>
            <p class="footer-text">PUBLISHING HOUSE PVT. LTD.</p>
          </div>
        </div>
        <div class="socials">
          <a href="#">
            <FontAwesomeIcon
              icon={faFacebook}
              className="a-image"
              style={{ color: "#a18c36" }}
            />
          </a>
          <a href="#">
            <FontAwesomeIcon
              icon={faLinkedin}
              className="a-image"
              style={{ color: "#a18c36" }}
            />
          </a>
          <a href="#">
            <FontAwesomeIcon
              icon={faYoutube}
              className="a-image"
              style={{ color: "#a18c36" }}
            />
          </a>
          <a href="#">
            <FontAwesomeIcon
              icon={faInstagram}
              className="a-image"
              style={{ color: "#a18c36" }}
            />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import React from "react";
import { motion } from "framer-motion";
const HomeElements = () => {
  return (
    <div className="element-container">
      <div className="group-conatiner-ele">
        <motion.div className="image-group"
        initial= {{x:-100, opacity:0}}
        whileInView={{x:0,opacity:1}}
        viewport={{once:true}}
        transition={{ type: "spring", duration: 3.5}}>
          <img src="images/bannerimage.jpg" alt="" className="image-box" />
          <img src="images/bannerimage.jpg" alt="" className="image-box" />
          <img src="images/bannerimage.jpg" alt="" className="image-box" />
          <img src="images/bannerimage.jpg" alt="" className="image-box" />
        </motion.div>
        <motion.div className="description"
        initial= {{x:100, opacity:0}}
        style={{ backgroundImage: "url('/images/design-pad-r.svg')" ,backgroundSize:"contain", backgroundRepeat:"no-repeat"}}
        whileInView={{x:0,opacity:1}}
        viewport={{once:true}}
        transition={{ type: "spring",duration: 3.5 }}
>
          <h2 className="heading">THIS WEEK IN SCIENCE</h2>
          <p className="desc">Read more about this week's headliner
          </p>
        </motion.div>
      </div>
      <div className="group-conatiner-ele">
        <motion.div className="image-group"
        initial= {{x:100, opacity:0}}
        whileInView={{x:0,opacity:1}}
        transition={{ type: "spring",duration: 3.5 }}
        viewport={{ once: true, amount: 0.5 }}>
        <img src="images/bannerimage.jpg" alt="" className="news-image " />
          <img src="images/bannerimage.jpg" alt="" className="news-image " />
   
        </motion.div>
        <motion.div
  className="description"
  style={{ backgroundImage: "url('/images/design-pad-l.svg')",backgroundSize:"contain", backgroundRepeat:"no-repeat" }}
  initial={{ x: -100, opacity: 0 }}
  whileInView={{ x: 0, opacity: 1 }}
  viewport={{once:true}}
  transition={{ type: "spring", duration: 3.5 }}
>
  <h2 className="heading"
  style={{color:"#120621"}}>LATEST
  RESEARCH</h2>
  <p className="desc"
  style={{color:"#5A378A"}}>Browse through our recent articles</p>
</motion.div>
      </div>
    </div>
  );
};

export default HomeElements;

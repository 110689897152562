// AuthModal.js
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../Styles/AuthModal.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom'; // Import useHistory for navigation
import { changeTitle } from "../utils/ChangeTitle";

Modal.setAppElement("#root");

const AuthModal = ({ isOpen, onClose }) => {
  const [isLogin, setIsLogin] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleLogin = (e) => {
    e.preventDefault();
    if (email && password) {
      toast.success("Login successful!");
      onClose();
    } else {
      toast.error("Please fill in all fields.");
    }
  };

  const handleSocialAuth = (provider) => {
    toast.info(`Signing in with ${provider}...`);
  };

  const navigateToSignUp = () => {
    navigate('/signup')
    onClose(); 
  };
  
  useEffect(()=>{
    changeTitle("Scitome-Signup")
  })
  
  useEffect(()=>{
    changeTitle("Scitome-Signup")
  })
  useEffect(()=>{
    changeTitle("Login | Scitome")
    return(
      changeTitle("Scitome Publications")
    )
  },[])
  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        contentLabel="Auth Modal"
        className="modal"
        overlayClassName="overlay"
      >
        <h2>{isLogin ? "Login" : "Create an account"}</h2>
        <p className="text-desc">Enter your email to sign up for this app</p>
        {isLogin ? (
          <form onSubmit={handleLogin}>
            <div className="input-group">
              <label>Email</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="input-group">
              <label>Password</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <button type="submit" className="submit-btn">Login</button>
          </form>
        ) : (
          <p>Please navigate to the sign-up page.</p>
        )}

        <div className="social-auth">
          <p className="divider-text">or continue with</p>
          <button
            onClick={() => handleSocialAuth("Google")}
            className="social-btn google"
          >
            <img
              src="google.svg"
              alt="Google icon"
              style={{ width: "20px", marginRight: "8px" }}
            />
            Google
          </button>
        </div>

        <div className="switch-auth">
          <p>
            {isLogin ? "Don't have an account?" : "Already have an account?"}
            <span onClick={navigateToSignUp}>
              {isLogin ? " Signup" : " Login"}
            </span>
          </p>
        </div>

        <button onClick={onClose} className="close-btn">
          <FontAwesomeIcon icon={faCircleXmark} style={{ color: 'rgb(212 208 208)', fontSize: '24px' }} />
        </button>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default AuthModal;

import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link } from 'react-router-dom'
import '../../Styles/sidebar.css'

const SideNavbar = () => {
  return (
    <div className='sidebar-container'>
        <input type="checkbox" name="" id="sidebar-active" />
        <label htmlFor="sidebar-active">
        <FontAwesomeIcon icon={faBars} className='burger-menu-icon' />
        </label>
        <div className="sidebar-links-container">
            <label htmlFor="sidebar-active">
            <FontAwesomeIcon icon={faXmark}  className='X-menu-icon' />
            </label>
            <Link to="/policy" className="privacy-links">Editorial</Link>
            <Link to="/policy/authorship-policies" className="privacy-links">Authorship</Link>
            <Link to="" className="privacy-links">Articles</Link>
            <Link to="" className="privacy-links">Policies  </Link>
        </div>
    </div>
  )
}

export default SideNavbar
// HoverCard.jsx
import React from 'react';


const GalleryCard = ({ 
  imageUrl = "/api/placeholder/400/320",
  heading = "Card Heading",
  subHeading = "This is the subheading text that appears on hover. It contains additional information about the card content.",
  imageAlt = "Card image"
}) => {
  return (
    <div className="hover-card">
      <img 
        src={imageUrl}
        alt={imageAlt} 
        className="hover-card__image"
      />
      <div className="hover-card__overlay">
        <div className="hover-card__content">
          <h2 className="hover-card__heading">{heading}</h2>
          <p className="hover-card__subheading">{subHeading}</p>
        </div>
      </div>
    </div>
  );
};

export default GalleryCard;
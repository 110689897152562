import React, { useState } from 'react';
import "../../Styles/home.css";
import { Link } from 'react-router-dom';
import AuthModal from './AuthModal';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <>
      <header className="header">
        <div className="header-container">
          <div className="nav-wrapper">
            {/* Logo Section */}
            <div className="logo-section">
              <div className="logo-nav-container">
                <div className="logo-nav">
                  <img  src="/images/scitome_pitch.svg" alt="SciTome"/>
                </div>
                <div className="logo-nav-text">
                <span className="company-name">SCITOME</span>
                <span className="company-subtitle">PUBLISHING HOUSE PVT. LTD.</span>
                </div>
              </div>
            </div>

            {/* Desktop Navigation */}
            <nav className="nav-menu">
              <Link className='a-link' href="#content">Content</Link>
              <Link className='a-link' href="#about">About Us</Link>
              <Link className='a-link' href="#publish">Publish with us</Link>
              <button onClick={openModal} className='login-btn' id="scitome-login">LOGIN</button>
            <AuthModal isOpen={isModalOpen} onClose={closeModal} />
            </nav>

            {/* Mobile Menu Button */}
            <button 
              className={`mobile-menu-btn ${isMenuOpen ? 'active' : ''}`}
              onClick={toggleMenu}
            >
              <span></span>
              <span></span>
              <span></span>
            </button>
          </div>

          {/* Mobile Navigation */}
          <nav className={`mobile-nav ${isMenuOpen ? 'active' : ''}`}>
            <a href="#content">Content</a>
            <a href="#about">About Us</a>
            <a href="#publish">Publish with us</a>
            <button className="login-btn">LOGIN</button>
            
          </nav>
        </div>
      </header>
    </>
  );
};

export default Header;
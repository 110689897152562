import React from 'react';

const AuthorshipPage = () => {
  return (
    <div className="policy-part-container">
      <h1 className="policy-title">Authorship</h1>
      <b><p>On this page</p></b>
      <ul className='in-page-links'>
    <li>
        <a href="#authorship" class="policy-a-link">Authorship</a>
    </li>
    <li>
        <a href="#authorship-inclusion-ethics-global-research" class="policy-a-link">Authorship: inclusion & ethics in global research</a>
    </li>
    <li>
        <a href="#consortia-authorship" class="policy-a-link">Consortia authorship</a>
    </li>
    <li>
        <a href="#author-contribution-statements" class="policy-a-link">Author contribution statements</a>
    </li>
    <li>
        <a href="#author-identification" class="policy-a-link">Author identification</a>
    </li>
    <li>
        <a href="#author-name-change" class="policy-a-link">Author name change</a>
    </li>
    <li>
        <a href="#nature-portfolio-journals-editorials" class="policy-a-link">Nature Portfolio journals' editorials</a>
    </li>
</ul>

    {/* Authorship section */}

    <h2 id='authorship' className="policy-section-title">Authorship</h2>
    <p className="policy-description">
        Authorship provides credit for a researcher's contributions to a study and carries accountability. Authors are expected to fulfill the criteria below (adapted from McNutt et al., <a className="policy-a-link" href="https://doi.org/10.1073/pnas.1715374115">Proceedings of the National Academy of Sciences, Feb 2018, 201715374</a>; licensed under CC BY 4.0):
      </p>
      <p className="policy-description">
        Each author is expected to have made substantial contributions to the conception or design of the work; or the acquisition, analysis, or interpretation of data; or the creation of new software used in the work; or have drafted the work or substantively revised it.
      </p>
      <p className="policy-description">
        AND to have approved the submitted version (and any substantially modified version that involves the author's contribution to the study);
      </p>
      <p className="policy-description">
        AND to have agreed both to be personally accountable for the author's own contributions and to ensure that questions related to the accuracy or integrity of any part of the work, even ones in which the author was not personally involved, are appropriately investigated, resolved, and the resolution documented in the literature.
      </p>
      <p className="policy-description">
        Nature Portfolio journals encourage collaboration with colleagues in the locations where the research is conducted and expect their inclusion as co-authors when they fulfill all authorship criteria described above. Contributors who do not meet all criteria for authorship should be listed in the Acknowledgements section.
      </p>
      <p className="policy-description">
        Nature Portfolio journals reserve the right not to consider non-primary research manuscripts that have been authored by medical writers. Writing assistance should be acknowledged in all article types.
      </p>
      <p className="policy-description">
        Nature Portfolio journals do not require all authors of a research paper to sign the letter of submission, nor do they impose an order on the list of authors. Submission to a Nature Portfolio journal is taken by the journal to mean that all the listed authors have agreed all of the contents, including the author list and author contribution statements. The corresponding author is responsible for having ensured that this agreement has been reached that all authors have agreed to be so listed and have approved the manuscript submission to the journal, and for managing all communication between the journal and all co-authors, before and after publication. The corresponding author is also responsible for submitting a competing interests' statement on behalf of all authors of the paper; please refer to our competing interests' policy for more information.
      </p>
      <p className="policy-description">
        It is expected that the corresponding author (and on multi-group collaborations, at least one member of each collaborating group, usually the most senior member of each submitting group or team, who accepts responsibility for the contributions to the manuscript from that team) will be responsible for the following with respect to data, code and materials: (adapted from McNutt et al., <a className="policy-a-link" href="https://doi.org/10.1073/pnas.1715374115">Proceedings of the National Academy of Sciences, Feb 2018, 201715374</a>; licensed under CC BY 4.0):
      </p>
      <ul className="policy-description">
        <li>ensuring that data, materials, and code comply with transparency and reproducibility standards of the field and journal;</li>
        <li>ensuring that original data/materials/code upon which the submission is based are preserved following best practices in the field so that they are retrievable for reanalysis;</li>
        <li>confirming that data/materials/code presentation accurately reflects the original;</li>
        <li>foreseeing and minimizing obstacles to the sharing of data/materials/code described in the work;</li>
        <li>ensuring that all authors (or group leaders in multi-lab collaborations) have certified the author list and author contributions.</li>
      </ul>
      <p className="policy-description">
        At submission, the corresponding author must include written permission from the authors of the work concerned for mention of any unpublished material cited in the manuscript (for example, others' data, in press manuscripts, personal communications, or work in preparation). The corresponding author also must clearly identify at submission any material within the manuscript (such as figures) that has been published previously elsewhere and provide written permission from authors of the prior work and/or publishers, as appropriate, for the re-use of such material.
      </p>
      <p className="policy-description">
        After acceptance, the corresponding author is responsible for the accuracy of all content in the proof, including the names of co-authors, addresses, and affiliations.
      </p>
      <p className="policy-description">
        After publication, the corresponding author is the point of contact for queries about the published paper. It is their responsibility to inform all co-authors of any matters arising in relation to the published paper and to ensure such matters are dealt with promptly. Authors of published material have a responsibility to inform the journal immediately if they become aware of any aspects that require correction.
      </p>
      <p className="policy-description">
        Any changes to the author list after submission, such as a change in the order of the authors or the deletion or addition of authors, must be approved by every author. Changes of authorship by adding or deleting authors, and/or changes in Corresponding Author, and/or changes in the sequence of authors are not permitted after acceptance of a manuscript. Nature Portfolio journal editors are not in a position to investigate or adjudicate authorship disputes before or after publication. Such disagreements, if they cannot be resolved amongst authors, should be directed to the relevant institutional authority.
      </p>
      <p className="policy-description">
        The primary affiliation for each author should be the institution where the majority of their work was done. If an author has subsequently moved, the current address may also be stated. Springer Nature remains neutral with regard to jurisdictional claims in published maps and institutional affiliations.
      </p>

      <h2 id='authorship-inclusion-ethics-global-research' className="policy-section-title">Authorship: inclusion & ethics in global research</h2>
      <p className="policy-description">
        Nature Portfolio journals encourage collaboration with colleagues in the locations where the research is conducted and expect their inclusion as co-authors when they fulfill all authorship criteria described above. Contributors who do not meet all criteria for authorship should be listed in the Acknowledgements section. We urge researchers to carefully consider researcher contributions and authorship criteria when involved in multi-region collaborations involving local researchers so as to promote greater equity in research collaborations.
      </p>
      <p className="policy-description">
        We encourage researchers to follow the recommendations set out in the <a className="policy-a-link" href="https://www.globalcodeofconduct.org">Global Code of Conduct for Research in Resource-Poor Settings</a> when designing, executing, and reporting their research and to provide a disclosure statement in their manuscript that covers the aspects listed below (drawn from the Global Code of Conduct). Editors may at their discretion ask authors to provide a disclosure statement taking these questions into account; the disclosure can be requested during peer review, shared with reviewers, and published in the final paper as an “Ethics & Inclusion statement” in the Methods section. Our general policies on Research ethics and Reporting standards can be found <a className="policy-a-link" href="https://www.nature.com/nature-research/editorial-policies/research-ethics">here</a> and <a className="policy-a-link" href="https://www.nature.com/nature-research/editorial-policies/reporting-standards">here</a>.
      </p>
      <ol className=''>
      <li>
        Has the research included local researchers throughout the research process – study design, study implementation, data ownership, intellectual property, and authorship of publications?
      </li>
      <li>
        Is the research locally relevant and has this been determined in collaboration with local partners?
      </li>
      <li>
        Please describe whether roles and responsibilities were agreed amongst collaborators ahead of the research and whether any capacity-building plans for local researchers were discussed.
      </li>
      <li>
        Would this research have been severely restricted or prohibited in the setting of the researchers? If yes, please provide details on specific exceptions granted for this research in agreement with local stakeholders.
      </li>
      <li>
        Where appropriate, has the study been approved by a local ethics review committee? If not, please explain the reasons.
      </li>
      <li>
        Where animal welfare regulations, environmental protection, and biorisk-related regulations in the local research setting were insufficient compared to the setting of the researchers, please describe if research was undertaken to the higher standards.
      </li>
      <li>
        Does the research result in stigmatization, incrimination, discrimination, or otherwise personal risk to participants? If yes, describe provisions to ensure safety and well-being of participants.
      </li>
      <li>
        If research involves health, safety, security, or other risks to researchers, describe any risk management plans undertaken.
      </li>
      <li>
        Have any benefit-sharing measures been discussed in case biological materials, cultural artifacts, or associated traditional knowledge has been transferred out of the country?
      </li>
      <li>
        Please indicate if you have taken local and regional research relevant to your study into account in citations.
      </li>
    </ol>
      

    <h2 id='consortia-authorship' className="policy-section-title">Consortia authorship</h2> 
    <p className="policy-description">
        A collective of authors can be listed as a consortium. If necessary, individual authors can be listed in both the main author list and as a member of a consortium. All authors within a consortium must be listed at the end of the paper.
      </p>
      <p className="policy-description">
        If it is necessary to include a list of consortium members that did not directly contribute to the paper, this list can be placed in the Supplementary Information. To facilitate submission of manuscripts with large author lists, please consult the journal editor before submission.
      </p>


      <h2 id='author-contribution-statements' className="policy-section-title">Author contribution statements</h2>
      <p className="policy-description">
        Nature Portfolio journals encourage transparency by publishing author contribution statements. Authors are required to include a statement of responsibility in the manuscript, including review-type articles, that specifies the contribution of every author. The level of detail varies; some disciplines produce manuscripts that comprise discrete efforts readily articulated in detail, whereas other fields operate as group efforts at all stages. Author contribution statements are included in the published paper. This Nature Editorial describes the policy in more detail.
      </p>
      <p className="policy-description">
        Nature Portfolio journals also allow one set of co-authors to be specified as having contributed equally to the work and one set of co-authors to be specified as having jointly supervised the work. Other equal contributions are best described in author contribution statements. Corresponding authors have specific responsibilities (described above).
      </p>


      <h2 id='author-identification' className="policy-section-title">Author identification</h2>
      <p className="policy-description">
        As part of our efforts to improve transparency and unambiguous attribution of scholarly contributions, corresponding authors of published papers must provide their Open Researcher and Contributor Identifier (ORCID) iD; co-authors are encouraged to provide ORCiD iDs. More information about Springer Nature’s support for ORCiD iDs and journals participating in the ORCiD mandate can be found <a href="https://orcid.org" className="policy-a-link">here</a>.
      </p>

      <h2 id='author-name-change' className="policy-section-title">Author name change      </h2>
      <p className="policy-description">
        An author who has changed their name for reasons such as gender transition or religious conversion may request for their name, pronouns, and other relevant biographical information to be corrected on papers published prior to the change. The author can choose for this correction to happen silently, in which case there will be no note flagging the change on either the PDF or the HTML of the paper, or alternatively, they may do so by a formal public Author Correction.
      </p>
      <p className="policy-description">
        For authors who’ve changed their name and wish to correct it on their published works, please see the <a href="https://www.springernature.com/gp/contact/support" className="policy-a-link">SNCS Contact Form: Inclusive Name Change Policy</a>: Springer Nature Support.
      </p>



      
      <h2 id='nature-portfolio-journals-editorials' className="policy-section-title">
        Nature Portfolio journals' editorials:
      </h2>
      <p className="policy-description">
        New framework aims to improve inclusion and ethics in global research collaborations amid wider efforts to end exploitative practices. <em>Nature.</em> Nature addresses helicopter research and ethics dumping, June 2022.
      </p>
      <p className="policy-description">
        Corresponding authors should not neglect their responsibility to a journal or their co-authors. <em>Nature Nanotechnology.</em> A matter of duty, December 2012.
      </p>
      <p className="policy-description">
        Why do we need statements to define the contributions made by each author? <em>Nature Photonics.</em> Contributors, guests, and ghosts, June 2012.
      </p>
      <p className="policy-description">
        Announcing "author contributions" statements, 2009:
      </p>
      <ol>
        <li>
          <em>Nature Nanotechnology.</em> The responsibilities of authors.
        </li>
        <li>
          <em>Nature Cell Biology.</em> Attribution and accountability.
        </li>
        <li>
          <em>Nature Physics.</em> What did you do?
        </li>
        <li>
          <em>Nature Photonics.</em> Combating plagiarism.
        </li>
        <li>
          <em>Nature.</em> Authorship policies.
        </li>
      </ol><br/><br/>
      <p className="policy-description">
        Individual contributions should be carefully evaluated when compiling the author list of a scientific paper. <em>Nature Materials.</em> Authorship matters, February 2008.
      </p>
      <p className="policy-description">
        How the responsibilities of co-authors for a scientific paper's integrity could be made more explicit. <em>Nature.</em> Who is accountable? 1 November 2007.
      </p>
      <p className="policy-description">
        The problems of unjustified authorship. <em>Nature Materials.</em> Authorship without authorization, November 2004.
      </p>
      <p className="policy-description">
        Nature is encouraging authors of papers to say who did what. <em>Nature.</em> Author contributions, 3 June 1999.
      </p>
    </div>
  );
};

export default AuthorshipPage;

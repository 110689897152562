import React from "react";
import "../../Styles/main.css";
import { Link } from "react-router-dom";
import { motion } from 'framer-motion';

const Main = () => {
  return (
    <div className="logo">
      <motion.div className="logo-title"
      initial={{ opacity: 0, y: 30 }}
      animate={{ opacity: [.2,.6,1], y: 0 }}
      transition={{ duration: 2.5, ease: [0.42, 0, 0.58, 1] }}   
      >
        <div className="logo-image">
          <h1>SciTome</h1>
          <p>for scientists, by scientists, of scientists</p>
        </div>
        <button>
          <Link className="signup-home-btn" to="/signup">
            Sign up for alerts
          </Link>
        </button>
      </motion.div>
      <motion.div 
      initial={{ opacity: 0, y: -30 }}
      animate={{ opacity: [.2,.6,1], y: 0 }}
      transition={{ duration: 2.5, ease: [0.42, 0, 0.58, 1] }}
      className="image">
        <img src="images/bannerimage.jpg" alt="" srcset="" />
        <p>Journal Name, Issue 1, Vol 1, 2024</p>
      </motion.div>
    </div>
  );
};

export default Main;

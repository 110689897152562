import React, { useState } from "react";

const CarouselCard = ({ head, url }) => {
  const [showPlaceholder, setShowPlaceholder] = useState(false);
  const handleVideoError = () => {
    setShowPlaceholder(true);
  };
  
  return (
    <div className="embla__slide embla-slide-adder">
      <div className="carousels">
        <div className="contents-carousel">
          <h3 className="carousel-content-head">{head}</h3>

          <div className="video-wrapper">
            {showPlaceholder ? (
              <div className="video-placeholder">
                Video unavailable. Check back later!
              </div>
            ) : (
              <iframe
                className="video-embed"
                src={url}
                title="YouTube Video"
                allowFullScreen
                onError={handleVideoError}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarouselCard;

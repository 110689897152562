// CardDetail.jsx
import React from 'react';
import { motion } from 'framer-motion';
import { useLocation, useNavigate } from 'react-router-dom';
import '../../Styles/gallery.css';


const CardDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { cardData } = location.state || {};

  if (!cardData) {
    return <div>No card data found</div>;
  }

  return (
    <motion.div 
      className="card-detail"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <button 
        className="card-detail__back-button"
        onClick={() => navigate(-1)}
      >
        ← Back to Collection
      </button>

      <motion.div 
        className="card-detail__content"
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.6 }}
      >
        <div className="card-detail__image-container">
        other links...
        </div>
      </motion.div>
    </motion.div>
  );
};

export default CardDetail;
// SignUpForm.js
import React, { useEffect, useMemo, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import countries from "../data/Countries";
import { changeTitle } from "../utils/ChangeTitle";

const SignUpForm = ({ onSubmit }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [country, setCountry] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [educationLevel, setEducationLevel] = useState("");
  const [affiliation, setAffiliation] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [UserType, setUserType] = useState("");

  useEffect(()=>{
    changeTitle(" Signup | Scitome")
  },[])


  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      firstName &&
      lastName &&
      country &&
      email &&
      phoneNumber &&
      educationLevel &&
      affiliation &&
      password === confirmPassword &&
      UserType
    ) {
      onSubmit({
        firstName,
        lastName,
        country,
        email,
        phoneNumber,
        educationLevel,
        affiliation,
        password,
        UserType
      });
    } else {
      toast.error("Please fill in all fields and make sure passwords match.");
    }
  };

  const countryOptions = useMemo(()=>{
  return countries.map((country)=>(
    <option value={country}>{country}</option>
  ))
  },[])

  return (
    <div className="modal-signup-container">
        <h2>Create an account</h2>
        <p className="text-desc">Enter your email to sign up for this app</p>
        <form className="signupmodal" onSubmit={handleSubmit}>
      <div className="group">
        <div className="input-group">
          <label>First Name</label>
          <input
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
          />
        </div>
        <div className="input-group">
          <label>Last Name</label>
          <input
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
          />
        </div>
        <div className="input-group">
          <label>Country of Residence</label>
          <select
          className="select-dropdown"
            value={country}
            onChange={(e) => setCountry(e.target.value)}
            required
          >
            <option value="">Select country</option>
           {countryOptions}
          </select>
        </div>
        <div className="input-group">
          <label>Email ID</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="input-group">
          <label>Phone Number</label>
          <input
            type="tel"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            required
          />
        </div>
        <div className="input-group">
          <label>Educational Qualification</label>
          <select
          className="select-dropdown"
            value={educationLevel}
            onChange={(e) => setEducationLevel(e.target.value)}
            required
          >
            <option value="">Select</option>
            <option value="BSc">BSc</option>
            <option value="BS">BS</option>
            <option value="MSc">MSc</option>
            <option value="MS">MS</option>
            <option value="PhD">PhD</option>
            <option value="MPhil">MPhil</option>
            <option value="Postdoctoral Research">Postdoctoral Research</option>
            <option value="Assistant Professor">Assistant Professor</option>
            <option value="Associate Professor">Associate Professor</option>
            <option value="Professor">Professor</option>
            <option value="Others">Others</option>
          </select>
        </div>
      </div>
      <div className="group">
        <div className="input-group">
          <label>Affiliation University</label>
          <input
            type="text"
            value={affiliation}
            onChange={(e) => setAffiliation(e.target.value)}
            
          />
        </div>
        <div className="input-group">
          <label>Password</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div className="input-group">
          <label>Confirm Password</label>
          <input
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </div>
        <div className="input-group">
          <label>Educational Qualification</label>
          <select
          className="select-dropdown"
            value={setUserType}
            onChange={(e) => setUserType(e.target.value)}
            required
          >
            <option value="">Select User type</option>
            <option value="author" >Author</option>
            <option value="reviewer">Reviewer</option>
            <option value="editor">Editor</option>
            <option value="">Others</option>
            
          </select>
        </div>
      </div>
      <ToastContainer />
    </form>
    <button type="submit" className="submit-btn">
    Signup
  </button>
    </div>
  );
};

export default SignUpForm;

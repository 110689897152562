export const changeTitle = (title, iconurl = null) => {
  document.title = title;

  if (iconurl) {
    const link = document.querySelector("link[rel~='icon']");
    if (!link) {
      const newLink = document.createElement("link");
      newLink.rel = "icon";
      newLink.href = iconurl;
      document.head.appendChild(newLink);
    } else {
      link.href = iconurl;
    }
  }
};
